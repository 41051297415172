var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vouchers-card" }, [
    _c("div", { staticClass: "vouchers-card__main" }, [
      _c("div", { staticClass: "fw500 bet a-center" }, [
        _c(
          "div",
          {
            style: {
              display: "flex",
              width: "100%",
            },
          },
          [
            _c("div", [
              _vm.data.item.type === 0
                ? _c("div", {
                    style: { "margin-right": "10px" },
                    domProps: { textContent: _vm._s(_vm.$t("Benefit")) },
                  })
                : _vm.data.item.type === 2
                ? _c("div", {
                    style: { "margin-right": "10px" },
                    domProps: { textContent: _vm._s(_vm.$t("Upsell")) },
                  })
                : _vm.data.item.type !== 2 && _vm.data.item.type !== 0
                ? _c("div", {
                    style: { "margin-right": "10px" },
                    domProps: { textContent: _vm._s(_vm.$t("Tab")) },
                  })
                : _vm._e(),
            ]),
          ]
        ),
        _vm.he
          ? _c("div", {
              staticClass: "grey",
              style: {
                "margin-bottom": "10px",
                "margin-top": "5px",
                "margin-right": "10px",
              },
              domProps: { textContent: _vm._s(_vm.data.item.name_he) },
            })
          : _vm._e(),
        !_vm.he
          ? _c("div", {
              staticClass: "grey",
              style: {
                "margin-bottom": "10px",
                "margin-top": "5px",
                "margin-right": "10px",
              },
              domProps: { textContent: _vm._s(_vm.data.item.name_en) },
            })
          : _vm._e(),
      ]),
      _c(
        "div",
        {
          staticClass: "vouchers-card__main_main",
          style: {
            display: "flex",
            "justify-content": "space-around",
            width: "100%",
            "margin-bottom": "10px",
          },
        },
        [
          _c("div", { on: { click: _vm.data.click } }, [
            _vm.data.item.publish
              ? _c("div", {
                  staticClass: "grey",
                  domProps: { textContent: _vm._s(_vm.$t("Date")) },
                })
              : _vm._e(),
            _vm.data.item.publish
              ? _c("div", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.formattedDate(_vm.data.item.publish)
                    ),
                  },
                })
              : _c("div", { domProps: { textContent: _vm._s("-") } }),
          ]),
          _c("div", { on: { click: _vm.data.click } }, [
            _vm.data.item.expiration
              ? _c("div", {
                  staticClass: "grey",
                  domProps: { textContent: _vm._s(_vm.$t("Date")) },
                })
              : _vm._e(),
            _vm.data.item.expiration
              ? _c("div", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.formattedDate(_vm.data.item.expiration)
                    ),
                  },
                })
              : _c("div", { domProps: { textContent: _vm._s("-") } }),
          ]),
          _vm.data.active
            ? _c("swich-button", {
                attrs: { value: _vm.data.item.active },
                on: { input: _vm.data.active },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }